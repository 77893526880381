<template>
  <el-row clas="cart">
    <el-col class="title">购物车({{carGoodsNum}})</el-col>
    <el-col :span="24">
      <CartHeader></CartHeader>
       
      <CartBuyInfoFreeMail  :itemDatas="goodDatas"></CartBuyInfoFreeMail>
      <CartBuyInfo :itemDatas="goodDatas"></CartBuyInfo>
      <CartBottom  @ChangeDatasFun="ChangeDatasFun"></CartBottom>
    </el-col>
  </el-row>
</template>

<script>
  const CartBottom = () => import("components/ShopBuy/CartBottom.vue");
  const CartBuyInfo = () => import("components/ShopBuy/CartBuyInfo.vue");
  const CartBuyInfoFreeMail = () => import("components/ShopBuy/CartBuyInfoFreeMail.vue");
  const CartHeader = () => import("components/ShopBuy/CartHeader.vue");
  import {
    mapActions,
    mapState
  } from "vuex";
  export default {
    name: 'Cart',
    data() {
      return {
        goodDatas:[],
      };
    },
    components: {
      CartHeader,
      CartBuyInfo,
      CartBottom,
      CartBuyInfoFreeMail,
    },
    methods: {
      ...mapActions({
        getGoods: "getGoods"
      }),
      ChangeDatasFun(data){
        this.goodDatas=data
      }
    },
    computed: {
      ...mapState({
        carGoodsNum: state => state.carGoodsNum
      })
    },
    created() {
      this.getGoods();
    }
  }
</script>

<style lang="less" scoped>
  @import '~style/index.less';

  .cart {
    overflow: hidden;

  }

  .title {
    color: #FFD303;
    font-size: 16px;
    line-height: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
  }
</style>